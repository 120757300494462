(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/wrap-login-link/wrap-login-link.js') >= 0) return;  svs.modules.push('/components/lb-utils/wrap-login-link/wrap-login-link.js');

'use strict';

const {
  urlMapping
} = svs.core;
const WrapLoginLink = _ref => {
  var _svs$core$config$data;
  let {
    isCurrentUserAuthenticated,
    children,
    classes,
    returnUrl,
    roles = [],
    isDisabledWrapper
  } = _ref;
  let authenticatedRole = false;
  const isSelfService = ((_svs$core$config$data = svs.core.config.data.svsconfig) === null || _svs$core$config$data === void 0 ? void 0 : _svs$core$config$data.isSelfService) || false;
  if (roles.length > 0) {
    authenticatedRole = Boolean(roles.find(role => svs.core.userSession.hasRole(role)));
  }
  if (isCurrentUserAuthenticated || authenticatedRole || isSelfService || isDisabledWrapper) {
    return children;
  }
  const currentReturnUrl = returnUrl || encodeURIComponent(location.pathname);
  const url = "".concat(urlMapping.get('loginHome'), "?returnUrl=").concat(currentReturnUrl);
  const classNames = ['qa-login-purchase-button', classes];
  return React.createElement("a", {
    className: classNames.filter(Boolean).join(' '),
    href: url
  }, children);
};
setGlobal('svs.components.lbUtils.wrapLoginLink.WrapLoginLink', WrapLoginLink);

 })(window);